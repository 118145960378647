<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="2"
        md="3"
      >
        <statistic-card-with-area-chart
          v-if="membersGained"
          icon="UsersIcon"
          :statistic="kFormatter(membersGained.count)"
          :statistic-title="$t('Members')"
          :chart-data="membersGained.series"
        />
      </b-col>
      <b-col
        xl="2"
        md="3"
      >
        <statistic-card-with-area-chart
          v-if="workingGroups"
          icon="CodesandboxIcon"
          color="warning"
          :statistic="kFormatter(workingGroups.count)"
          :statistic-title="$t('Working Groups')"
          :chart-data="workingGroups.series"
        />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="eventStatistics" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- <b-col lg="4">
        <b-row class="match-height">
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col> -->

      <!-- Revenue Report Card -->
      <!-- <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col> -->
      <!--/ Revenue Report Card -->
    </b-row>
  </section>
</template>

<script>
import axios from '@/libs/axios'
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
// import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
// import EcommerceOrderChart from './EcommerceOrderChart.vue'
// import EcommerceProfitChart from './EcommerceProfitChart.vue'
// import EcommerceEarningsChart from './EcommerceEarningsChart.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    EcommerceStatistics,
    // EcommerceRevenueReport,
    // EcommerceOrderChart,
    // EcommerceProfitChart,
    // EcommerceEarningsChart,
  },
  data() {
    return {
      data: {},
      membersGained: {
        series: [
          {
            name: this.$t('Members'),
            data: [28, 40, 36, 52, 38, 60, 55],
          },
        ],
        count: 6200,
      },
      workingGroups: {
        series: [
          {
            name: this.$t('Working Groups'),
            data: [10, 15, 8, 15, 7, 12, 8],
          },
        ],
        count: 0,
      },
      eventStatistics: [],
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
    //dimitris
    this.$router.push({ name: 'voting-list' })
    this.updateEventStats()
    this.updateWorkingGroupStats()
  },
  methods: {
    async updateEventStats() {
      const response = await axios.get('statistics/events')
      this.eventStatistics = this.createEventStatsObj(response.data)
    },
    async updateWorkingGroupStats() {
      const response = await axios.get('statistics/working-groups')
      this.workingGroups.count = response.data.count
    },
    createEventStatsObj(item) {
      return [
        {
          icon: 'GlobeIcon',
          color: 'light-primary',
          title: item.panhellenic,
          subtitle: this.$t('Panhellenic'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CodesandboxIcon',
          color: 'light-info',
          title: item.wGSeminars,
          subtitle: this.$t('WG seminars'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-danger',
          title: item.peripheral,
          subtitle: this.$t('Peripheral'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CastIcon',
          color: 'light-success',
          title: item.webinars,
          subtitle: this.$t('Webinars'),
          customClass: '',
        },
      ]
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
